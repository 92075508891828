#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
}

#header .header1 .container .title {
    text-align: left;
    max-width: 700px;
    line-height: 60px;
    color: white;
    font-size: 60px;
}

#header .header1 .container .text {
    color: white;
    max-width: 700px;
}

#header .header2 {
    background-color: #F3F5F2;
}

#header .header2 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

#header .header2 .container .left img {
    border-radius: 7px;
}

#header .header2 .container .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .right .title {
    text-align: left;
}

#header .header2 .container .right .mobile {
    display: none;
}

#header .header2 .container .right #ctaWpp {
    width: 50%;
}

#header .header3 .container .text {
    text-align: center;
}

#header .header3 .container .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

#header .header3 .container .gallery .item {
    background-color: #F3F5F2;
    border-radius: 7px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#header .header3 .container .gallery .item .top img {
    width: 35%;
}

#header .header3 .container .gallery .item .item_title {
    text-align: center;
    color: #078BCC;
    font-weight: bold;
    font-size: 20px;
}

#header .header3 .container .gallery .item .item_text {
    text-align: center;
    font-size: 16px;
}

#header .header3 .container .gallery .item #ctaWpp {
    margin: auto auto 0;
    width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
        height: 80vh;
    }

    #header .header1 .container {
        justify-content: flex-end;
    }

    #header .header1 .container .title {
        text-align: center;
        max-width: 100%;
        font-size: 50px;
    }

    #header .header1 .container .text {
        max-width: 100%;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }

    #header .header2 .container {
        grid-template-columns: 1fr;
    }

    #header .header2 .container .left {
        display: none;
    }

    #header .header2 .container .right .title {
        text-align: center;
    }

    #header .header2 .container .right .mobile {
        display: block;
    }

    #header .header2 .container .right .mobile img {
        border-radius: 7px;
        width: 80%;
    }

    #header .header2 .container .right .text {
        text-align: center;
    }

    #header .header2 .container .right #ctaWpp {
        margin: 0 auto;
        width: 60%;
    }

    #header .header3 .container .gallery {
        grid-template-columns: 1fr;
    }

    #header .header3 .container .gallery .item .item_title {
        font-size: 18px;
    }

    #header .header3 .container .gallery .item .item_text {
        font-size: 15px;
    }

    #header .header3 .container .gallery .item #ctaWpp {
        width: 60%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .title {
        font-size: 40px;
    }

    #header .header2 .container .right #ctaWpp {
        width: 75%;
    }

    #header .header3 .container .gallery .item .item_title {
        font-size: 16px;
    }

    #header .header3 .container .gallery .item .item_text {
        font-size: 14px;
    }

    #header .header3 .container .gallery .item #ctaWpp {
        width: 75%;
    }
}
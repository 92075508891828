#formPag .container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

#formPag .container .title {
  font-weight: bold;
  text-align: center;
}

#formPag .container .title span {
  color: black;
  font-weight: 400;
}

#formPag .container .text {
  text-align: center;
  color: #435762;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
}

#formPag .container figure {
  margin-top: -2rem;
}

#formPag .container figure img {
  width: 40%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #formPag .container .text {
    font-size: 20px;
  }

  #formPag .container figure img {
    width: 55%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #formPag .container .text {
    font-size: 16px;
  }

  #formPag .container figure {
    margin-top: -1rem;
  }
}
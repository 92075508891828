#ctaWpp {
    background-color: #03DD5A;
    font-weight: 700;
    padding: .9rem .5rem;
    width: 30%;
    height: fit-content;
    border-radius: 10rem;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaWpp a figure {
    width: 8%;
}

#ctaWpp a figure img {
    width: 100%;
    display: block;
}

#ctaWpp p {
    font-size: 20px;
    font-weight: 700;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 60%;
    }

    #ctaWpp a figure {
        width: 6%;
    }

    #ctaWpp p {
        font-size: 20px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 75%;
    }

    #ctaWpp p {
        font-size: 16px;
    }
}
#ctaUnidades {
    background-color: #FF5C00;
    font-weight: 700;
    padding: .9rem .5rem;
    width: 30%;
    height: fit-content;
    border-radius: 10rem;
}

#ctaUnidades a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaUnidades p {
    font-size: 20px;
    font-weight: 700;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaUnidades {
        width: 60%;
    }

    #ctaUnidades p {
        font-size: 20px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaUnidades {
        width: 75%;
    }

    #ctaUnidades p {
        font-size: 16px;
    }
}
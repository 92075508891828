#menu .container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .left {
    text-align: left;
    position: absolute;
    top: 0;
    width: 10%;
}

#menu .container .left img {
    width: 100%;
}

#menu .container #CtaTelWpp #ctaTel,
#menu .container #CtaTelWpp #ctaWpp,
#menu .container #CtaTelWpp #ctaUnidades {
    width: 16%;
}

#menu .container #CtaTelWpp #ctaTel p,
#menu .container #CtaTelWpp #ctaWpp p,
#menu .container #CtaTelWpp #ctaUnidades p {
    font-size: 16px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container .left {
        width: 25%;
    }

    #menu .container #CtaTelWpp #ctaTel,
    #menu .container #CtaTelWpp #ctaWpp {
        display: none;
    }

    #menu .container #CtaTelWpp #ctaUnidades {
        width: 40%;
        margin-left: auto;
    }

    #menu .container #CtaTelWpp #ctaUnidades p {
        font-size: 13px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container #CtaTelWpp #ctaUnidades {
        width: 50%;
    }

    #menu .container #CtaTelWpp #ctaUnidades p {
        font-size: 10px;
    }
}
#CtaTelWpp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #CtaTelWpp {
    flex-direction: column;
  }

  #CtaTelWpp #ctaWpp,
  #CtaTelWpp #ctaTel {
    width: 100%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}
#about .about1 {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
  height: 80vh;
}

#about .about1 .container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

#about .about1 .container .title {
  color: white;
  text-align: left;
  max-width: 700px;
}

#about .about1 .container .text {
  color: white;
  max-width: 700px;
}

#about .about2 {
  background-color: #F3F5F2;
}

#about .about2 .container {
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;
  gap: 4rem;
}

#about .about2 .container .left img {
  border-radius: 7px;
}

#about .about2 .container .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .right .title {
  text-align: left;
}

#about .about2 .container .right .mobile {
  display: none;
}

#about .about2 .container .right #ctaWpp {
  width: 50%;
}

#about .about3 .container .text {
  text-align: center;
}

#about .about3 .container .text2 {
  text-align: center;
  color: var(--color-title);
  font-weight: bold;
  font-size: 30px;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

#about .about3 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about3 .container .gallery .item .item_title {
  text-align: center;
  font-weight: bold;
  color: #0075FF;
}

#about .about3 .container .gallery .item #ctaWpp {
  margin: 1rem auto 0;
  width: 90%;
}

#about .about4 {
  background: linear-gradient(#0075FF, #078BCC);
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about4 .container .title {
  color: #BDFF00;
  font-size: 36px;
}

#about .about4 .container .title span {
  color: white;
}

#about .about4 .container .text {
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 30px;
}

#about .about4 .container #CtaTelWpp {
  justify-content: center;
}

#about .about4 .container #CtaTelWpp #ctaTel {
  background-color: white;
}

#about .about4 .container #CtaTelWpp #ctaTel p {
  color: #0075FF;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 {
    background: url("../../assets/mobile/about1.png") no-repeat center 0;
    background-size: cover;
    height: 90vh;
  }

  #about .about1 .container {
    justify-content: flex-end;
  }

  #about .about1 .container .title {
    text-align: center;
    max-width: 100%;
  }

  #about .about1 .container .text {
    text-align: center;
    max-width: 100%;
  }

  #about .about1 .container #ctaWpp {
    margin: 0 auto;
  }

  #about .about2 .container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about .about2 .container .left {
    display: none;
  }

  #about .about2 .container .right .title {
    text-align: center;
  }

  #about .about2 .container .right .mobile {
    display: block;
    width: 90vw;
  }

  #about .about2 .container .right .text {
    text-align: center;
  }

  #about .about2 .container .right #ctaWpp {
    width: 60%;
    margin: 0 auto;
  }

  #about .about3 .container .text2 {
    font-size: 25px;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
  }

  #about .about3 .container .gallery .item .top img {
    width: 80%;
  }

  #about .about3 .container .gallery .item #ctaWpp {
    width: 60%;
  }

  #about .about4 .container .title {
    font-size: 30px;
  }

  #about .about4 .container .text {
    font-size: 25px;
  }

  #about .about4 .container #CtaTelWpp #ctaTel {
    width: 60%;
  }

  #about .about4 .container #CtaTelWpp #ctaWpp {
    width: 60%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 {
    background: url("../../assets/mobile/about1.png") no-repeat center 0;
    background-size: cover;
    height: 80vh;
  }

  #about .about2 .container .right #ctaWpp {
    width: 75%;
  }

  #about .about3 .container .text2 {
    font-size: 20px;
  }

  #about .about3 .container .gallery .item #ctaWpp {
    width: 75%;
  }

  #about .about4 .container .title {
    font-size: 25px;
  }

  #about .about4 .container .text {
    font-size: 20px;
  }

  #about .about4 .container #CtaTelWpp #ctaTel {
    width: 75%;
  }

  #about .about4 .container #CtaTelWpp #ctaWpp {
    width: 75%;
  }
}
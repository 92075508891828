#modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

#modal .close {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 50px;
  cursor: pointer;
  color: white;
}

#modal .modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

#modal .modal-content .image-grid {
  display: grid;
  grid-template-columns: repeat(2, 400px);
  gap: 1rem;
}

#modal .modal-content img {
  max-width: 100%;
  border-radius: 7px;
}

#modal .modal-content .navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

#modal .modal-content .navigation button {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

#modal .modal-content .navigation button:hover {
  background-color: #666;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #modal .modal-content .image-grid {
    grid-template-columns: repeat(2, 300px);
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #modal .modal-content .image-grid {
    grid-template-columns: 1fr;
  }
}